<template>
  <div>
    <v-container class="page-title page-title--advanced">
      <v-row>
        <v-col class="page-title__title-box">
          <h4 class="page-title__title">{{ $t('heading.instance.advanced.developer.title') }}</h4>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="pb-2"
          v-if="instance.getUserPrivileges('wordpress.manage_debug_mode')"
        >
          <auto-updates-card-skeleton v-if="fetchingDebugMode" />

          <v-card elevation="4" v-else>
            <v-card-text
              class="d-flex justify-space-between text--gray-darken2"
            >
              <div>
                <h6>{{ $t('heading.instance.advanced.developer.debug.title') }}</h6>
                <p class="mb-0 mt-1">
                  {{ $t('heading.instance.advanced.developer.debug.subtitle') }}
                </p>
              </div>
              <div class="d-flex align-center">
                <button-switch
                  class="mt-0"
                  :loading="submitDebugMode"
                  :value="debugModeStatus"
                  @click.native.stop.prevent="toggleDebugMode"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          class="pb-2"
          v-if="instance.getUserPrivileges('wordpress.manage_maintenance_mode')"
        >
          <auto-updates-card-skeleton v-if="fetchingMaintenanceMode" />

          <v-card elevation="4" v-else>
            <v-card-text
              class="d-flex justify-space-between text--gray-darken2"
            >
              <div>
                <h6>{{ $t('heading.instance.advanced.developer.maintenance.title') }}</h6>
                <p class="mb-0 mt-1">
                  {{ $t('heading.instance.advanced.developer.maintenance.subtitle') }}
                </p>
              </div>
              <div class="d-flex align-center">
                <button-switch
                  class="mt-0"
                  :loading="submitMaintenanceMode"
                  :value="maintenanceModeStatus"
                  @click.native.stop.prevent="toggleMaintenanceMode"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="instance.hasFeature('hosting.phpmyadmin_sso')"
          cols="12"
          class="pb-2"
        >
          <v-card elevation="4">
            <v-card-text
              class="d-flex justify-space-between text--gray-darken2 v-card--mobile"
            >
              <div>
                <h6>{{ $t('heading.instance.advanced.developer.phpmyadmin.title') }}</h6>
                <p class="mb-0 mt-1">
                  {{ $t('heading.instance.advanced.developer.phpmyadmin.subtitle') }}
                </p>
                <AlertSm
                  :class="'alert-sm--primary mt-3'"
                  :text="$t('heading.instance.advanced.developer.phpmyadmin.alert')"
                />
              </div>

              <div class="d-flex align-center">
                <v-btn
                  elevation="0"
                  block
                  class="font-weight-bold v-btn--default"
                  :href="'#'"
                  :target="'_blank'"
                  :loading="phpmyadminSsoLoading"
                  @click.prevent="phpmyadminSsoLogin(instance.hostingId)"
                >
                  {{ $t('button.login.phpmyadmin') }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="instance.hasFeature('hosting.change_php_version')"
          cols="12"
          class="pb-2"
        >
          <auto-updates-card-skeleton v-if="phpCurrentVersionLoading" />
          <v-card elevation="4" v-else>
            <v-card-text
              class="d-flex justify-space-between text--gray-darken2"
            >
              <div>
                <h6>{{ $t('heading.instance.advanced.developer.phpVersion.title') }}</h6>
                <p class="mb-0 mt-1">{{ $t('heading.instance.advanced.developer.phpVersion.subtitle') }}</p>
                <AlertSm
                  :class="'alert-sm--primary mt-3'"
                  :text="$t('heading.instance.advanced.developer.phpVersion.alert')"
                />
              </div>
              <div class="d-flex align-center">
                <span class="p-3 d-flex align-center mx-4">
                  {{ $t('heading.instance.advanced.developer.phpVersion.status', {version: phpCurrentVersion}) }}
                </span>

                <v-btn
                  elevation="0"
                  class="mt-0 pt-0"
                  outlined
                  color="gray"
                  small
                  :loading="phpVersionsLoading"
                  @click="loadPhpVersionsModal()"
                >
                  <span class="heading--text font-weight-bold">{{ $t('button.manage') }}</span>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
          <PhpVersionModal
            :open.sync="phpVersionModalOpen"
            :instance="instance"
            :currentVersion.sync="phpCurrentVersion"
            :versions="phpVersions"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Api from "@/apis/Api";

import AutoUpdatesCardSkeleton from "@/components/cards/AutoUpdatesCardSkeleton.vue";
import ButtonSwitch from "../../../components/buttons/ButtonSwitch.vue";
import AlertSm from "../../../components/alerts/AlertSm.vue";
import PHPMyAdminMixin from "../../../mixins/PHPMyAdminMixin";
import PhpVersionModal from "./DeveloperSettings/PhpVersionModal.vue";

export default {
  name: "DeveloperSettings",
  components: {
    AutoUpdatesCardSkeleton,
    ButtonSwitch,
    AlertSm,
    PhpVersionModal,
  },
  mixins: [PHPMyAdminMixin],
  props: {
    instance: Object,
  },
  data() {
    return {
      submitting: false,
      submitDebugMode: false,
      submitMaintenanceMode: false,
      fetchingMaintenanceMode: false,
      fetchingDebugMode: false,
      applicationId: this.$route.params.id,
      maintenanceModeStatus: false,
      debugModeStatus: false,
      //
      phpVersionModalOpen: false,
      phpCurrentVersionLoading: false,
      phpCurrentVersion: "",
      phpVersionsLoading: false,
      phpVersions: [],
    };
  },
  mounted() {
    this.fetchMaintenanceModeStatus();
    this.fetchDebugModeStatus();
    if (this.instance.hasFeature("hosting.change_php_version")) {
      this.loadCurrentPhpVersion();
    }
  },
  methods: {
    fetchMaintenanceModeStatus() {
      this.fetchingMaintenanceMode = true;
      Api.get(`/instances/${this.applicationId}/wordpress/maintenance-mode`)
        .then((response) => {
          this.maintenanceModeStatus = response.data.status;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.fetchingMaintenanceMode = false;
        });
    },
    fetchDebugModeStatus() {
      this.fetchingDebugMode = true;
      Api.get(`/instances/${this.applicationId}/wordpress/debug-mode`)
        .then((response) => {
          this.debugModeStatus = response.data.status;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.fetchingDebugMode = false;
        });
    },
    async toggleMaintenanceMode() {
      try {
        this.submitMaintenanceMode = true;

        await this.saveMaintenanceMode();
      } finally {
        this.submitMaintenanceMode = false;
      }
    },
    async toggleDebugMode() {
      this.submitDebugMode = true;

      try {
        await this.saveDebugMode();
      } finally {
        this.submitDebugMode = false;
      }
    },
    async saveDebugMode() {
      return Api.put(`/instances/${this.applicationId}/wordpress/debug-mode`, {
        status: !this.debugModeStatus,
      })
        .then(() => {
          this.instance.setDebugMode(this.debugModeStatus);

          this.debugModeStatus = !this.debugModeStatus;

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.settings.update')
          });

          return true;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });

          return false;
        });
    },
    async saveMaintenanceMode() {
      return Api.put(
        `/instances/${this.applicationId}/wordpress/maintenance-mode`,
        { status: !this.maintenanceModeStatus }
      )
        .then(() => {
          this.instance.setMaintenanceMode(this.maintenanceModeStatus);

          this.maintenanceModeStatus = !this.maintenanceModeStatus;

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.settings.update')
          });

          return true;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });

          return false;
        });
    },
    loadCurrentPhpVersion() {
      this.phpCurrentVersionLoading = true;
      Api.cached()
        .get(
          `/server-accounts/${this.instance.hostingId}/domains/${this.instance.domain}/php-version`
        )
        .then((response) => {
          this.phpCurrentVersion = response.data.data;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.phpCurrentVersionLoading = false;
        });
    },
    loadPhpVersionsModal() {
      this.phpVersionsLoading = true;
      Api.cached()
        .get(`/server-accounts/${this.instance.hostingId}/php-versions`)
        .then((response) => {
          this.phpVersions = response.data.data;
          this.phpVersionModalOpen = true;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.phpVersionsLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card--mobile {
  @media (max-width: 767px) {
    flex-direction: column;
    .v-btn--default {
      margin-top: 16px;
    }
  }
}
</style>